import { AppMenuProps, MenuItem } from '@nextbusiness/infinity-ui'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'

export const applicationMenu = (
  history: RouterHistory,
  activeBetaFeatures: string[]
): AppMenuProps => {
  const settings: MenuItem[] = [
    {
      text: 'Dokumentdesign',
      onClick: () => history.push('/optionen/design'),
      icon: 'appearance',
    },
    {
      text: 'QR-Rechnung',
      onClick: () => history.push('/optionen/qr'),
      icon: 'qr-code',
    },
    {
      text: 'Mehrwertsteuer',
      onClick: () => history.push('/optionen/mwst'),
      icon: 'tax',
    },
    {
      text: 'Funktionen',
      onClick: () => history.push('/optionen/features'),
      icon: 'checked-checkbox',
    },
    {
      text: 'Benachrichtigungen',
      onClick: () => history.push('/optionen/benachrichtigungen'),
      icon: 'notification',
    },
    {
      text: 'Profitabilitätsziele',
      onClick: () => history.push('/optionen/profitabilitaet'),
      icon: 'target',
    },
    {
      text: 'Vorlagen',
      onClick: () => history.push('/optionen/vorlagen'),
      icon: 'project-setup-2',
    },
    {
      text: 'Verbuchung',
      onClick: () => history.push('/optionen/verbuchung'),
      icon: 'calculator',
    },
  ]

  if (activeBetaFeatures.includes('multi-currency')) {
    settings.push({
      text: 'Währungen',
      onClick: () => history.push('/optionen/waehrungen'),
      icon: 'bonds',
    })
  }

  return {
    groups: [
      {
        title: 'Finance-Einstellungen',
        buttons: settings,
      },
    ],
  }
}
