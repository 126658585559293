import { Finance } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  MoneyField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import useBackendQuery from 'libs/networking/BackendQuery'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import { useRootStore } from 'stores/RootStoreContext'
import BookkeepingIcon from '../../assets/settings-icons/bookkeeping-settings-icon.svg'

const BookkeepingSettingsPage = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [loadedSettings, isLoading] = useBackendQuery(() =>
    Finance.Organisation.current()
  )
  const [maximumRounding, setMaximumRounding] = useState<number | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const saveChanges = async () => {
    setIsSaving(true)
    try {
      const preferences = await Finance.Organisation.setPreferences({
        maximumInvoicePaymentRoundingAmount:
          maximumRounding !== null ? maximumRounding * 100 : null,
      })
      runInAction(() => {
        preferencesStore.organisationPreferences = preferences
      })
      notificationCenter.addNotification({
        children: 'Einstellungen gespeichert',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Einstellungen konnten nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (
      loadedSettings?.maximumInvoicePaymentRoundingAmount !== null &&
      loadedSettings?.maximumInvoicePaymentRoundingAmount !== undefined
    ) {
      setMaximumRounding(
        loadedSettings.maximumInvoicePaymentRoundingAmount / 100
      )
    }
  }, [loadedSettings])

  useMixpanelEvent('Settings viewed - Bookkeeping')

  if (isLoading) return <LoadingScreen />

  return (
    <SettingsPage
      title='Verbuchung'
      iconURL={BookkeepingIcon}
      className='bookkeeping-settings-page'
    >
      <SettingsGroup
        title='Rundungsdifferenzen'
        description='Für Zahlungen von Rechnungen und Forderungen kannst du hier einstellen, bis zu wieviel Rundungsdifferenz eine Rechnung noch als vollständig bezahlt gilt.'
      >
        <FormField label='Maximale Rundungsdifferenz'>
          <MoneyField
            placeholder='0.05'
            value={maximumRounding}
            onChange={(value) => setMaximumRounding(value)}
          />
        </FormField>
      </SettingsGroup>
      <Button
        variant='primary'
        isLoading={isSaving}
        onClick={() => saveChanges()}
      >
        Speichern
      </Button>
    </SettingsPage>
  )
}

export default observer(BookkeepingSettingsPage)
