import { Button } from '@nextbusiness/infinity-ui'
import { useTransactionCreatorContext } from './TransactionCreatorContext'

const TransactionCreatorPostButton = () => {
  const { createTransaction, transaction, isCreating } =
    useTransactionCreatorContext()

  return (
    <Button
      className='post-button'
      variant='primary'
      onClick={() => createTransaction()}
      disabled={
        !transaction.amount ||
        !transaction.creditAccount ||
        !transaction.debitAccount ||
        !!(transaction.originalCurrency && !transaction.exchangeRate)
      }
      isLoading={isCreating}
    >
      Verbuchen
    </Button>
  )
}

export default TransactionCreatorPostButton
